import React, { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

//Components
import WorkCard from "../common/WorkCard"

//SVG
import SelectedWorks from "../../data/svg/selected-works.svg"

//Framer motion Variants
const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }

const showSpinner = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: -20,
    transition: { delay: 2.8, ...transition },
  },
}

const parentContainer = {
  initial: {
    y: 0,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.04,
    },
  },
}

export const Grid = styled(motion.div)`
  margin-top: 8rem;
  display: grid;
  grid-template-columns: repeat(${(props) => props.theme.gridColumns}, 1fr);
  gap: 12px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    position: static !important;
  }
`
export const Content = styled(motion.div)`
  margin: -4rem auto 11em auto;
  max-width: ${(props) => props.theme.maxWidths.general};
  position: relative;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledDiv = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  > svg {
    display: inline-block;
    animation: ${rotate} 8s linear infinite;
    transform-origin: center;
    height: 14em;
    position: relative;
    left: 0;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 2em 0;
    margin-bottom: -2em;
    > svg {
      position: relative;
      left: 0;
      height: 7em;
      width: 7em;
    }
  }
`

//Per filter knop query veranderen
// content type query dynamisch met knop
//nieuwe data in een lijst tonen

const Works = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulImagePost(filter: { selectedWork: { eq: true } }) {
        nodes {
          id
          title
          slug
          desc {
            id
          }
          id
          photo {
            file {
              url
              fileName
            }
            fixed(width: 760, quality: 90) {
              src
              base64
              tracedSVG
              srcWebp
              srcSetWebp
            }
          }
          selectedWork
        }
      }
    }
  `)

  const animation = useAnimation()
  const animation2 = useAnimation()

  const [contentRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px",
    threshold: 0,
  })
  //When in view start both animations, by using the UseAnmations hook
  useEffect(() => {
    if (inView) {
      // console.log(inView)
      animation2.start("animate")
      animation.start("animate")
    }
  }, [animation, inView])

  return (
    <>
      <Content
        className
        ref={contentRef}
        animate={animation}
        // initial="intial"
        variants={parentContainer}
      >
        <StyledDiv
          initial="initial"
          animate={animation2}
          variants={showSpinner}
        >
          <SelectedWorks />
        </StyledDiv>
        <Grid>
          {data.allContentfulImagePost.nodes.map((posts, idx) => {
            return (
              <WorkCard
                key={idx}
                cover={posts.photo.fixed}
                path={posts.slug}
                name={posts.title}
              ></WorkCard>
            )
          })}
        </Grid>
      </Content>
    </>
  )
}
export default Works

// <GalleryItem className="item" key={idx} variants={AnimateItem}>
//   <Link to={`/works/${posts.slug}`}>
//     <h2>
//       <StyledTitle variants={AnimateTitle}>
//         {posts.title}
//       </StyledTitle>
//     </h2>
//     <Img fixed={posts.photo.fixed} objectFit="cover" />

//   </Link>
//   <h3>
//     <span>{posts.desc.desc}</span>
//   </h3>
//   {console.log(posts)}
// </GalleryItem>
