import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

const CardItem = styled(Link)`
  min-height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${(props) => props.theme.color.white};
  transition: all 0.3s ease-in-out;



  &:hover {
    color: white;
    transform: translateY(-6px);
  }
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    min-height: 300px;
  }


`

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  div {
    overflow: hidden;
  }
`

const Content = styled.div`
  padding: 1rem;
  position: relative;
  transition: all 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
  opacity: 0;
  height: 0;

  ${CardItem}:hover & {
    opacity: 1;
    height: 120px;
  }
`

const Name = styled.h2`
  font-size: 52px;
  display: flex;
  place-content: center;
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
  line-height: auto;
`

//Motion Variants
const transition = { duration: 0.7, ease: [0.43, 0.13, 0.23, 0.96] }

const AnimateItem = {
  intial: {
    y: 0,
    opacity: 0,
  },
  animate: {
    y: -82,
    opacity: 1,
    transition: { delay: 2.9, duration: 2.2, ...transition },
  },
}

const MotionWrapper = styled(motion.div)`
  .no-hover {
    pointer-events:none;
    color: red;
  }
`

const WorkCard = ({ path, cover, name, className }) => {
  return (
    <MotionWrapper variants={AnimateItem} className={className}>
      <CardItem to={`/works/${path}`} className={path ? `item` : `no-hover`}>
   
        <Cover>
          <Img fluid={cover} />
        </Cover>
        <Content>
          <Name>{name}</Name>
        </Content>
      </CardItem>
    </MotionWrapper>
  )
}

export default WorkCard
